// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../../tree/Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as LevelType from "../../tree/LevelType.js";
import * as ProfileId from "../../accounts/ProfileId.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as ReviewUpload__AnswerCard from "./ReviewUpload__AnswerCard.js";
import * as ReviewUpload__SireAnswersReview from "./ReviewUpload__SireAnswersReview.js";

function drawLevelsAccordions(vnode, questions, levels, vessel_id, userProfiles) {
  var currentProfile = State__Memory.Current.get().profile;
  var profileId = Curry._2(Prelude.OptionExported.$$Option.map, State__Memory.Current.get().profile, (function (i) {
          return i.id;
        }));
  var profileById = ProfileId.$$Map.fromArray(userProfiles.map(function (i) {
            return [
                    i.id,
                    i
                  ];
          }));
  return levels.map(function (level) {
              return ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("input", {
                                  hidden: true,
                                  id: level.id,
                                  name: "accordion-checkbox",
                                  type: "checkbox"
                                }),
                            ReactDOM.jsxs("label", {
                                  children: [
                                    React.jsx(Feather.make, {
                                          icon: "chevron_right",
                                          className: "icon",
                                          style: "top: 0.25rem; position: relative; float: right; margin-right: 0.2rem;",
                                          size: 18
                                        }),
                                    level.name
                                  ],
                                  className: "accordion-header text-primary h5",
                                  htmlFor: level.id
                                }),
                            ReactDOM.jsx("div", {
                                  children: questions.map(function (question) {
                                        var answer = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.last(Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keep, vnode.attrs.answers, (function (a) {
                                                            return Caml_obj.equal(a.question_id, question.id);
                                                          })), (function (a) {
                                                        return a.timestamp;
                                                      }), undefined)), Answer.Defaults.$$null);
                                        var answerProfile = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, answer.profile_id, (function (pId) {
                                                    return ProfileId.$$Map.get(profileById, pId);
                                                  })), undefined);
                                        if (Caml_obj.equal(answer.level_id, level.id)) {
                                          return React.jsx(ReviewUpload__AnswerCard.make, {
                                                      answer: answer,
                                                      level: level,
                                                      question: question,
                                                      answerProfile: answerProfile,
                                                      allowResolve: vnode.attrs.allowResolve ? Answer.questionVisibilityGetter(question)(currentProfile, answerProfile) : false,
                                                      allowEdit: Caml_obj.equal(answer.profile_id, profileId),
                                                      vesselId: vessel_id
                                                    });
                                        } else {
                                          return Mithril.empty;
                                        }
                                      }),
                                  className: "accordion-body"
                                })
                          ],
                          className: "accordion"
                        });
            });
}

function drawRootAccordions(vnode, questions, levels, vessel_id, roots, userProfiles) {
  return roots.map(function (root) {
              var children = Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                      if (l.path.startsWith(root.path)) {
                        return Caml_obj.notequal(l.id, root.id);
                      } else {
                        return false;
                      }
                    }));
              if (Prelude.$$Array.isEmpty(children)) {
                return Mithril.empty;
              } else {
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("input", {
                                    hidden: true,
                                    id: root.id,
                                    name: "accordion-checkbox",
                                    type: "checkbox"
                                  }),
                              ReactDOM.jsxs("label", {
                                    children: [
                                      React.jsx(Feather.make, {
                                            icon: "chevron_right",
                                            className: "icon",
                                            style: "top: 0.25rem; position: relative; float: right; margin-right: 0.2rem;",
                                            size: 18
                                          }),
                                      root.name
                                    ],
                                    className: "accordion-header text-primary h5",
                                    htmlFor: root.id
                                  }),
                              ReactDOM.jsx("div", {
                                    children: drawLevelsAccordions(vnode, questions, children, vessel_id, userProfiles),
                                    className: "accordion-body"
                                  })
                            ],
                            className: "accordion"
                          });
              }
            });
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var question_ids = vnode.attrs.answers.map(function (a) {
                          return a.question_id;
                        });
                    var profileIds = Prelude.$$Array.keepSome(vnode.attrs.answers.map(function (a) {
                              return a.profile_id;
                            }));
                    var level_ids = vnode.attrs.answers.map(function (a) {
                          return a.level_id;
                        });
                    var answer = Prelude.$$Array.first(vnode.attrs.answers);
                    var vessel_id = answer !== undefined ? answer.vessel_id : VesselId.$$null;
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.newAnswers = Answer.ofQuestions(question_ids), newrecord.questions = {
                                TAG: "In",
                                _0: question_ids
                              }, newrecord.levelTypes = LevelType.anyOfKind([
                                    "section",
                                    "structural",
                                    "safety",
                                    "structural_component",
                                    "safety_component",
                                    "sire",
                                    "sire_chapter",
                                    "company_form",
                                    "checklist",
                                    "checklist_part"
                                  ]), newrecord.levels = {
                                TAG: "In",
                                _0: level_ids
                              }, newrecord.newUserProfiles = "All", newrecord.userProfiles = {
                                TAG: "In",
                                _0: profileIds
                              }, newrecord)), (function (param) {
                            var newAnswers = param.newAnswers;
                            var questions = param.questions;
                            var levelTypes = param.levelTypes;
                            var levels = param.levels;
                            var newUserProfiles = param.newUserProfiles;
                            var userProfiles = param.userProfiles;
                            var newrecord = Caml_obj.obj_dup(Query.makeRead());
                            Prelude.thenDo(Query.read((newrecord.levels = Level.ofKinds(levelTypes.map(function (lt) {
                                                return lt.id;
                                              })), newrecord)), (function (param) {
                                    Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          questions: questions,
                                          levels: levels,
                                          roots: param.levels,
                                          newAnswers: newAnswers,
                                          vessel_id: vessel_id,
                                          levelTypes: levelTypes,
                                          userProfiles: Curry._2(Prelude.$$Array.concat, userProfiles, newUserProfiles)
                                        });
                                  }));
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var userProfiles = match.userProfiles;
                var levelTypes = match.levelTypes;
                var vessel_id = match.vessel_id;
                var roots = match.roots;
                var levels = match.levels;
                var questions = match.questions;
                var sireLevelTypes = LevelType.ids(Curry._2(Prelude.$$Array.keep, levelTypes, (function (l) {
                            return Prelude.$$Array.includes([
                                        "sire",
                                        "sire_chapter"
                                      ], l.kind);
                          })));
                var companyFormLevelTypes = LevelType.ids(Curry._2(Prelude.$$Array.keep, levelTypes, (function (l) {
                            return l.kind === "company_form";
                          })));
                var sireAndCompanyFormLevelTypes = Curry._2(Prelude.$$Array.concat, sireLevelTypes, companyFormLevelTypes);
                var otherRoots = Curry._2(Prelude.$$Array.keep, roots, (function (r) {
                        if (Prelude.$$Array.includes(sireAndCompanyFormLevelTypes, r.kind)) {
                          return false;
                        } else {
                          return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                            return l.path.startsWith(r.path);
                                          })));
                        }
                      }));
                var sireRoots = Curry._2(Prelude.$$Array.keep, roots, (function (r) {
                        if (Prelude.$$Array.includes(sireLevelTypes, r.kind) && r.depth === 1) {
                          return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                            return l.path.startsWith(r.path);
                                          })));
                        } else {
                          return false;
                        }
                      }));
                var companyFormLevels = Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                        return Prelude.$$Array.includes(companyFormLevelTypes, l.kind);
                      }));
                return Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, [Prelude.$$Array.isNotEmpty(otherRoots) ? ReactDOM.jsxs("div", {
                                        children: [
                                          ReactDOM.jsx("h5", {
                                                children: "Inspections"
                                              }),
                                          drawRootAccordions(vnode, questions, levels, vessel_id, otherRoots, userProfiles)
                                        ],
                                        className: "ratingState card card-shadow p-2"
                                      }) : Mithril.empty], Prelude.$$Array.isNotEmpty(companyFormLevels) ? [ReactDOM.jsxs("div", {
                                        children: [
                                          ReactDOM.jsx("h5", {
                                                children: "Company Forms"
                                              }),
                                          drawLevelsAccordions(vnode, questions, companyFormLevels, vessel_id, userProfiles)
                                        ],
                                        className: "ratingState card card-shadow p-2"
                                      })] : []), Prelude.$$Array.isNotEmpty(sireRoots) ? [React.jsx(ReviewUpload__SireAnswersReview.make, {
                                    questions: questions,
                                    answers: vnode.attrs.answers,
                                    vesselId: vessel_id,
                                    levelsAnswered: levels,
                                    allowResolve: vnode.attrs.allowResolve,
                                    userProfiles: userProfiles
                                  })] : []);
              }));
}

export {
  drawLevelsAccordions ,
  drawRootAccordions ,
  make ,
}
/* Level Not a pure module */
