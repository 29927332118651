// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../../tree/Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LevelType from "../../tree/LevelType.js";
import * as ProfileId from "../../accounts/ProfileId.js";
import * as QuestionId from "../../tree/QuestionId.js";
import * as Caml_module from "rescript/lib/es6/caml_module.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as ReviewUpload__AnswerCard from "./ReviewUpload__AnswerCard.js";

var LevelAccordion = Caml_module.init_mod([
      "ReviewUpload__SireAnswersReview.res",
      21,
      48
    ], {
      TAG: "Module",
      _0: [[
          "Function",
          "make"
        ]]
    });

async function _toggleState(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return "Closed";
  }
  var descendentLevels = vnode.attrs.descendentLevels;
  var level = vnode.attrs.level;
  var levelAnswers = Curry._2(Prelude.$$Array.keep, vnode.attrs.answers, (function (a) {
          return Caml_obj.equal(a.level_id, level.id);
        }));
  var subLevels = Curry._2(Prelude.$$Array.keep, descendentLevels, (function (d) {
          return Caml_obj.equal(d.parent_id, Caml_option.some(level.id));
        }));
  return {
          TAG: "Open",
          subLevels: subLevels,
          levelAnswers: levelAnswers
        };
}

function make(param) {
  return Mithril.view(Mithril.component("Closed"), (function (vnode) {
                var level = vnode.attrs.level;
                var vesselId = vnode.attrs.vesselId;
                var currentProfile = vnode.attrs.currentProfile;
                var profileById = vnode.attrs.profileById;
                var allowResolve = vnode.attrs.allowResolve;
                var descendentLevels = vnode.attrs.descendentLevels;
                var questionById = vnode.attrs.questionById;
                var answers = vnode.attrs.answers;
                var idPrefix = vnode.attrs.idPrefix;
                var allowEdit = vnode.attrs.allowEdit;
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var subLevels = match.subLevels;
                  tmp = subLevels.length !== 0 ? subLevels.map(function (child) {
                          var descendent = Curry._2(Prelude.$$Array.keep, descendentLevels, (function (l) {
                                  if (l.path.startsWith(child.path)) {
                                    return Caml_obj.notequal(l.id, child.id);
                                  } else {
                                    return false;
                                  }
                                }));
                          return React.jsx(LevelAccordion.make, {
                                      level: child,
                                      descendentLevels: descendent,
                                      vesselId: vesselId,
                                      currentProfile: currentProfile,
                                      profileById: profileById,
                                      allowResolve: allowResolve,
                                      answers: answers,
                                      questionById: questionById,
                                      allowEdit: allowEdit,
                                      idPrefix: idPrefix
                                    });
                        }) : match.levelAnswers.map(function (answer) {
                          var question = QuestionId.$$Map.get(questionById, answer.question_id);
                          var answerProfile = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, answer.profile_id, (function (pId) {
                                      return ProfileId.$$Map.get(profileById, pId);
                                    })), undefined);
                          return React.jsx(ReviewUpload__AnswerCard.make, {
                                      answer: answer,
                                      level: level,
                                      question: question,
                                      answerProfile: answerProfile,
                                      allowResolve: allowResolve ? Answer.questionVisibilityGetter(question)(currentProfile, answerProfile) : false,
                                      allowEdit: Prelude.default(allowEdit, Caml_obj.equal(answer.profile_id, Curry._2(Prelude.OptionExported.$$Option.map, currentProfile, (function (p) {
                                                      return p.id;
                                                    })))),
                                      vesselId: vesselId
                                    });
                        });
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("input", {
                                    hidden: true,
                                    id: idPrefix + "-" + level.id,
                                    name: "accordion-checkbox",
                                    type: "checkbox",
                                    onChange: (function (extra) {
                                        return Prelude.thenDo(_toggleState(vnode), (function (extra) {
                                                      return Mithril.setState(vnode, extra);
                                                    }));
                                      })
                                  }),
                              ReactDOM.jsxs("label", {
                                    children: [
                                      React.jsx(Feather.make, {
                                            icon: "chevron_right",
                                            className: "icon",
                                            style: "top: 0.25rem; position: relative; float: right; margin-right: 0.2rem;",
                                            size: 18
                                          }),
                                      level.name
                                    ],
                                    className: "accordion-header text-primary h5",
                                    htmlFor: idPrefix + "-" + level.id
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "accordion-body"
                                  })
                            ],
                            className: "accordion"
                          });
              }));
}

Caml_module.update_mod({
      TAG: "Module",
      _0: [[
          "Function",
          "make"
        ]]
    }, LevelAccordion, {
      make: make
    });

function make$1(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), (async function (vnode) {
                    var vesselId = vnode.attrs.vesselId;
                    var answers = vnode.attrs.answers;
                    var questions = vnode.attrs.questions;
                    var questionById = QuestionId.$$Map.fromArray(questions.map(function (q) {
                              return [
                                      q.id,
                                      q
                                    ];
                            }));
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    var match = await Query.read((newrecord.sire2VettingPeriods = {
                            TAG: "Limit",
                            _0: 1,
                            _1: {
                              TAG: "Is",
                              _0: "vessel_id",
                              _1: vesselId
                            }
                          }, newrecord.levelTypes = LevelType.ofKind("sire_chapter"), newrecord));
                    var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
                    var match$1 = await Query.read((newrecord$1.levels = {
                            TAG: "And",
                            _0: Level.ofVessel(vesselId),
                            _1: Level.ofKinds(match.levelTypes.map(function (lt) {
                                      return lt.id;
                                    }))
                          }, newrecord$1));
                    var levels = match$1.levels;
                    var isSelfOrAncestor = function (path, level) {
                      if (level.path === path) {
                        return true;
                      } else {
                        return path.startsWith(level.path);
                      }
                    };
                    var sire2VettingPeriodId = Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.sire2VettingPeriods), (function (v) {
                            return v.id;
                          }));
                    var match$2;
                    if (sire2VettingPeriodId !== undefined) {
                      var sire2VettingPeriodId$1 = Caml_option.valFromOption(sire2VettingPeriodId);
                      match$2 = Curry._2(Prelude.$$Array.partition, answers, (function (a) {
                              return Caml_obj.equal(a.sire2_vetting_period_id, Caml_option.some(sire2VettingPeriodId$1));
                            }));
                    } else {
                      match$2 = [
                        [],
                        answers
                      ];
                    }
                    var answersInCompletedPeriod = match$2[1];
                    var answersInActivePeriod = match$2[0];
                    var match$3 = Curry._3(Prelude.$$Array.fold, answersInActivePeriod.map(function (a) {
                              return a.level_path;
                            }), [
                          [],
                          levels
                        ], (function (param, path) {
                            var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                                    return isSelfOrAncestor(path, extra);
                                  }));
                            var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
                            return [
                                    branches,
                                    match[1]
                                  ];
                          }));
                    var levelsInActivePeriod = match$3[0];
                    var chaptersInActivePeriod = Curry._2(Prelude.$$Array.keep, levelsInActivePeriod, (function (chapter) {
                            return chapter.depth === 2;
                          }));
                    var match$4 = Curry._3(Prelude.$$Array.fold, answersInCompletedPeriod.map(function (a) {
                              return a.level_path;
                            }), [
                          [],
                          levels
                        ], (function (param, path) {
                            var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                                    return isSelfOrAncestor(path, extra);
                                  }));
                            var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
                            return [
                                    branches,
                                    match[1]
                                  ];
                          }));
                    var levelsInCompletedPeriod = match$4[0];
                    var chaptersInCompletedPeriod = Curry._2(Prelude.$$Array.keep, levelsInCompletedPeriod, (function (chapter) {
                            return chapter.depth === 2;
                          }));
                    return Mithril.setState(vnode, {
                                TAG: "Loaded",
                                chaptersInActivePeriod: chaptersInActivePeriod,
                                levelsInActivePeriod: levelsInActivePeriod,
                                answersInActivePeriod: answersInActivePeriod,
                                chaptersInCompletedPeriod: chaptersInCompletedPeriod,
                                levelsInCompletedPeriod: levelsInCompletedPeriod,
                                answersInCompletedPeriod: answersInCompletedPeriod,
                                questionById: questionById
                              });
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var questionById = match.questionById;
                var answersInCompletedPeriod = match.answersInCompletedPeriod;
                var levelsInCompletedPeriod = match.levelsInCompletedPeriod;
                var chaptersInCompletedPeriod = match.chaptersInCompletedPeriod;
                var answersInActivePeriod = match.answersInActivePeriod;
                var levelsInActivePeriod = match.levelsInActivePeriod;
                var chaptersInActivePeriod = match.chaptersInActivePeriod;
                var vesselId = vnode.attrs.vesselId;
                var userProfiles = vnode.attrs.userProfiles;
                var allowResolve = vnode.attrs.allowResolve;
                var currentProfile = State__Memory.Current.get().profile;
                var profileById = ProfileId.$$Map.fromArray(userProfiles.map(function (i) {
                          return [
                                  i.id,
                                  i
                                ];
                        }));
                return Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, [], Prelude.$$Array.isEmpty(chaptersInActivePeriod) ? [] : [
                                  ReactDOM.jsxs("div", {
                                        children: [
                                          ReactDOM.jsx("h5", {
                                                children: "SIRE 2.0 Active vetting period"
                                              }),
                                          chaptersInActivePeriod.map(function (c) {
                                                return React.jsx(LevelAccordion.make, {
                                                            level: c,
                                                            descendentLevels: Curry._2(Prelude.$$Array.keep, levelsInActivePeriod, (function (l) {
                                                                    return l.path.startsWith(c.path);
                                                                  })),
                                                            vesselId: vesselId,
                                                            currentProfile: currentProfile,
                                                            profileById: profileById,
                                                            allowResolve: allowResolve,
                                                            answers: answersInActivePeriod,
                                                            questionById: questionById,
                                                            idPrefix: "active-period"
                                                          });
                                              })
                                        ],
                                        className: "ratingState card card-shadow p-2"
                                      }),
                                  ReactDOM.jsx("div", {
                                        className: "divider blank"
                                      })
                                ]), Prelude.$$Array.isEmpty(chaptersInCompletedPeriod) ? [] : [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h5", {
                                            children: "SIRE 2.0 Completed vetting periods"
                                          }),
                                      chaptersInCompletedPeriod.map(function (c) {
                                            return React.jsx(LevelAccordion.make, {
                                                        level: c,
                                                        descendentLevels: Curry._2(Prelude.$$Array.keep, levelsInCompletedPeriod, (function (l) {
                                                                return l.path.startsWith(c.path);
                                                              })),
                                                        vesselId: vesselId,
                                                        currentProfile: currentProfile,
                                                        profileById: profileById,
                                                        allowResolve: allowResolve,
                                                        answers: answersInCompletedPeriod,
                                                        questionById: questionById,
                                                        allowEdit: false,
                                                        idPrefix: "completed-period"
                                                      });
                                          })
                                    ],
                                    className: "ratingState card card-shadow p-2"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  })
                            ]);
              }));
}

export {
  LevelAccordion ,
  make$1 as make,
}
/* LevelAccordion Not a pure module */
